<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold white--text mb-2">
          {{ t('video_quality.video_quality') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('video_quality.video_quality_detail') }}
        </p>
      </v-card-text>

      <v-card-text class="py-0">
        <div class="video-container">
          <div
            v-if="!videoLoaded"
            class="loading-indicator"
          >
            <v-progress-circular
              class="absolute-progress"
              color="dark-blue"
              indeterminate
              :size="66"
              :width="5"
            ></v-progress-circular>
          </div>
          <video-player
            v-show="videoLoaded"
            id="refVideoPlayer"
            ref="refVideoPlayer"
            class="video-player-box"
            :options="playerOptions"
            :playsinline="true"
            custom-event-name="customstatechangedeventname"
            @ready="playerReadied"
          ></video-player>
          <v-chip
            class="absolute-sample-chip text-xs"
            color="#1d1d1dd4"
          >
            {{ t('videos.sample_video') }}
          </v-chip>
        </div>
      </v-card-text>

      <v-card-text>
        <v-item-group
          v-model="itemSelected"
          mandatory
        >
          <v-row>
            <v-col cols="6">
              <v-item v-slot="{ active, toggle }">
                <v-card
                  class="rounded-lg"
                  :class="{'gold-border': active}"
                  @click="toggle"
                >
                  <v-card-text class="pa-3">
                    <v-icon
                      class="absolute-crown"
                      color="#FFCC00"
                      size="20"
                    >
                      {{ icons.mdiCrownOutline }}
                    </v-icon>
                    <div>
                      <v-icon
                        size="50"
                        :color="active ? 'white' : ''"
                      >
                        {{ icons.mdiVideoOutline }}
                      </v-icon>
                    </div>
                    <span
                      :class="{ 'white--text': active }"
                    >
                      {{ t('video_quality.premium') }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>

            <v-col cols="6">
              <v-item v-slot="{ active, toggle }">
                <v-card
                  class="rounded-lg"
                  :class="{'white-border': active}"
                  @click="toggle"
                >
                  <v-card-text class="pa-3">
                    <div>
                      <v-icon
                        size="50"
                        :color="active ? 'white' : ''"
                      >
                        {{ icons.mdiVideoOffOutline }}
                      </v-icon>
                    </div>
                    <span
                      :class="{ 'white--text': active }"
                    >
                      {{ t('video_quality.basic') }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card-text>

      <v-card-text>
        <p class="mb-2 text-center text-start">
          {{ itemSelected === 0 ? `${t('video_quality.premium_video')}` : t('video_quality.free_video') }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-btn
          block
          color="secondary"
          type="submit"
          class="mt-6 text-capitalize"
          style="height: 50px !important;"
          @click="onContinue"
        >
          {{ t('tooltip.continue') }}
        </v-btn>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable no-lonely-if */
import { ref, watch, onMounted } from '@vue/composition-api'
import {
  mdiVideoOutline,
  mdiVideoOffOutline,
  mdiCrownOutline,
} from '@mdi/js'
import 'video.js/dist/video-js.css'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency } from '@core/utils'
import themeConfig from '@themeConfig'
import useVideoData from '@core/utils/useVideoData'

export default {
  setup() {
    const { t } = useUtils()
    const { router } = useRouter()
    const { videoData, currentVideoData, updateVideoData } = useVideoData()

    const loginForm = ref(null)
    const itemSelected = ref(0)

    const premiumVideo = ref('https://fanaty-production-ohio.s3.amazonaws.com/highlights/685e0c38/highlight_with_outro.mp4')
    const basicVideo = ref('https://fanaty-production-ohio.s3.amazonaws.com/highlights/0da96093/highlight_with_outro.mp4')
    const playerOptions = ref({
      controls: false,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      fluid: true,
      aspectRatio: '16:9',
    })
    const videoLoaded = ref(false)

    const onContinue = async () => {
      if (itemSelected.value === 0) router.push({ name: 'views-pay-video' })
      else {
        if (videoData.value.match) router.push({ path: `/game/${videoData.value.match}` })
        else {
          videoData.value.video.check_redirect = true
          await updateVideoData(videoData.value)

          const hash = videoData.value.video.video_share_url.split('/r/')[1]
          router.push({ name: 'recording', params: { hash } })
        }
      }
    }

    const playerReadied = () => {
      videoLoaded.value = true
      const videoHTML = document.getElementsByTagName('video')
      if (videoHTML.length) {
        videoHTML[0].style.backgroundColor = '#333333'
        videoHTML[0].classList.add('bg-grey')
      }
    }

    watch([itemSelected], () => {
      videoLoaded.value = false
      if (itemSelected.value === 0) {
        playerOptions.value.sources = [{
          type: 'video/mp4',
          src: premiumVideo.value,
        }]
      } else {
        playerOptions.value.sources = [{
          type: 'video/mp4',
          src: basicVideo.value,
        }]
      }
    })

    onMounted(() => {
      Object.assign(videoData.value, currentVideoData.value)

      playerOptions.value.sources.push({
        type: 'video/mp4',
        src: premiumVideo.value,
      })
    })

    return {
      // data
      loginForm,
      itemSelected,
      videoData,

      premiumVideo,
      basicVideo,
      playerOptions,
      videoLoaded,

      // methods
      formatCurrency,
      onContinue,
      playerReadied,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      t,

      icons: {
        mdiVideoOutline,
        mdiVideoOffOutline,
        mdiCrownOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
<style lang="scss" scoped>
  .absolute-crown {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .gold-border {
    border: 2px solid #FFCC00 !important;

  }

  .white-border {
    border: 2px solid #FFFFFF !important;
  }

  .app-content-container {
    padding: 0px !important;
  }

  .video-container {
    border-radius: 10px;
    overflow: hidden;
    background-color: #333333;
    position: relative;
  }

  .loading-indicator {
    padding-top: 56.25%;
    position: relative;
  }

  .absolute-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video-player-box .video-js, .bg-grey {
    background-color: #333333;
  }

  .absolute-sample-chip {
    bottom: 10px;
    left: 10px;
    position: absolute;
    height: 24px;
  }
</style>
